<template>
  <div class="my-10">
    <div class="w-full">
      <div class="flex items-center">
        <h1 class="w-1/5">Date</h1>
        <h1 class="w-1/5">Time</h1>
        <h1 class="w-1/5">Family Name</h1>
        <h1 class="w-1/5">Location</h1>
        <h1 class="w-1/5"></h1>
      </div>
      <template v-for="enquiry in enquiries">
        <div
          class="flex items-center bg-white py-3 px-5 rounded-md my-4 hover:shadow-md cursor-pointer"
          :key="enquiry.id"
        >
          <div
            class="w-1/5 flex items-center gap-3"
            @click="$emit('view', enquiry)"
          >
            <calendar-icon class="opacity-30" />
            {{ parseDate(enquiry.created_at) }}
          </div>
          <div class="w-1/5" @click="$emit('view', enquiry)">
            {{ parseTime(enquiry.created_at) }}
          </div>
          <div class="w-1/5" @click="$emit('view', enquiry)">
            {{ enquiry.family_name }}
          </div>
          <div
            class="w-1/5 flex gap-4 text-primary font-bold items-center px-5 py-2 rounded-3xl bg-primary bg-opacity-20"
            @click="$emit('view', enquiry)"
          >
            <location-icon />
            {{ enquiry.location }}
          </div>
          <div class="w-1/5 flex gap-3 items-center justify-center">
            <div
              class="p-4 bg-blue-500 bg-opacity-20 rounded-full"
              @click="$emit('edit', enquiry)"
            >
              <edit-icon />
            </div>
            <div
              class="p-4 bg-red-500 bg-opacity-20 rounded-full"
              @click="$emit('delete', enquiry.id)"
            >
              <delete-icon />
            </div>
          </div>
        </div>
      </template>
    </div>
    <div class="flex justify-end gap-3">
      <template v-for="page in Math.ceil(total / perPage)">
        <div
          class="bg-white py-2 px-4 cursor-pointer rounded-md"
          :key="page"
          :class="
            page === currentPage
              ? 'bg-primary cursor-default text-white shadow-md'
              : ''
          "
          @click="$emit('fetchPage', page)"
        >
          {{ page }}
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "enquiryTable",
  components: {
    CalendarIcon: () => import("@/assets/icons/CalendarIcon"),
    LocationIcon: () => import("@/assets/icons/LocationIcon.vue"),
    EditIcon: () => import("@/assets/icons/EditIcon.vue"),
    DeleteIcon: () => import("@/assets/icons/DeleteIcon.vue"),
  },
  props: {
    allEnquiries: {
      required: true,
      type: Array,
    },
    perPage: {
      required: true,
      type: Number,
    },
    total: {
      required: true,
      type: Number,
    },
    currentPage: {
      required: true,
      type: Number,
    },
  },
  data() {
    return {
      pageNumber: 0,
    };
  },
  methods: {
    parseDate(date) {
      return moment(date).format("D MMM, YYYY");
    },
    parseTime(time) {
      return moment(time).format("hh:mm a");
    },
  },
  computed: {
    enquiries() {
      return this.allEnquiries;
    },
  },
};
</script>

<style>
.menu:hover .hidden {
  display: block;
}
</style>
